import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [
  { key: 'contact-us', text: 'Contact Us', url: '/contact-us' }
]

const ContactUsPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Contact Us Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default ContactUsPage
